import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import Solution from "../views/Solution.vue";
import Partner from "../views/Partner.vue";
import Career from "../views/Career.vue";
import Contact from "../views/Contact.vue";
import Legal from "../views/Legal.vue";
import SFOA from "../views/SFOA.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about-us",
    name: "About",
    component: About,
  },
  {
    path: "/solutions",
    name: "Solution",
    component: Solution,
  },
  {
    path: "/partners",
    name: "Partner",
    component: Partner,
  },
  {
    path: "/careers",
    name: "Career",
    component: Career,
  },
  {
    path: "/contact-us",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/legal",
    name: "Legal",
    component: Legal,
  },
  {
    path: "/sfoa",
    name: "SFOA",
    component: SFOA,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
