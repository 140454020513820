<template>
  <div class="partner">
    <PartnerContent />
  </div>
</template>

<script>
// @ is an alias to /src
import PartnerContent from "@/components/PartnerContent.vue";

export default {
  name: "Partner",
  components: {
    PartnerContent,
  },
};
</script>
