<template>
  <v-card flat height="70" tile>
    <v-toolbar extended dark src="/imgs/navBar-2.jpg" extension-height="15">
      <v-col md="1" class="hidden-md-and-down"></v-col>
      <v-toolbar-title class="text-h4 hidden-sm-and-down" style="cursor: pointer" @click="$router.push('/')"
        >CNT Corp</v-toolbar-title
      >
      <v-toolbar-title
        class="text-h4 display-1 mt-2 hidden-md-and-up"
        style="cursor: pointer"
        @click="$router.push('/')"
        >CNT Corp</v-toolbar-title
      >
      <v-spacer />
      <v-toolbar-items class="hidden-sm-and-down mr-12">
        <v-btn
          v-for="item in links"
          :key="item.id"
          text
          :color="item.router === active ? '#65acc1' : '#fffffe'"
          @click="go(item.router)"
        >
          {{ item.name }}
        </v-btn>
      </v-toolbar-items>
      <v-menu class="hidden-md-and-up">
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on" class="hidden-md-and-up">
            <v-icon large>mdi-menu</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="item in links"
            :key="item.id"
            text
            :color="item.router === active ? '#65acc1' : '#fffffe'"
            @click="go(item.router)"
          >
            <v-list-tile-title>{{ item.name }}</v-list-tile-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      active: "",
      links: [
        {
          name: "Home",
          router: "/",
        },
        {
          name: "About Us",
          router: "/about-us",
        },
        {
          name: "Solutions",
          router: "/solutions",
        },
        {
          name: "Partners",
          router: "/partners",
        },
        {
          name: "Careers",
          router: "/careers",
        },
        {
          name: "Contact Us",
          router: "/contact-us",
        },
      ],
    };
  },

  watch: {
    $route(to) {
      this.active = to.path;
    },
  },

  mounted() {
    this.active = this.$route.path;
  },

  methods: {
    go(ip) {
      this.$router.push({ path: ip });
    },
  },
};
</script>
