<template>
  <div class="solution">
    <SolutionContent />
  </div>
</template>

<script>
// @ is an alias to /src
import SolutionContent from "@/components/SolutionContent.vue";

export default {
  name: "Solution",
  components: {
    SolutionContent,
  },
};
</script>
