<template>
  <v-container>
    <v-row class="hidden-md-and-up">
      <v-col cols="12" md="4" class="mt-3">
        <v-card color="#65acc1" dark>
          <v-card-title class="text-h4 mb-3">
            About Us
          </v-card-title>
          <v-divider inset />

          <v-card-text class=" mt-1"> </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="text-left">
      <v-col class="mt-3" cols="12">
        <p class="text-justify">
          Formed in 2003, the company is privately owned and funded by private investors, hence the entity has a debt
          free balance sheet and has been operating on its own since its inception.As a licensed Telecommunications
          Carrier, CNTCorp is a wholesale provider specializing in fibre to the home and WiMAX solutions.
        </p>
      </v-col>
    </v-row>
    <v-row justify="space-between">
      <v-col md="5">
        <v-card class="mb-5" min-width="380">
          <v-img src="/imgs/about-1.jpg" height="300"></v-img>

          <v-card-title>
            Our Team
          </v-card-title>
          <v-divider inset />

          <v-card-subtitle class="mt-2">
            Our team of Account Managers are experts in their field, but their clients are always their first priority.
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col md="5">
        <v-card class="mb-5" min-width="380">
          <v-img src="/imgs/about-2.jpg" height="300"></v-img>

          <v-card-title>
            Going Beyond
          </v-card-title>
          <v-divider inset />

          <v-card-subtitle class="mt-2">
            In addition to providing a customised solution, our specialists will assist in future proofing your
            business.
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    show: false,
  }),
};
</script>
