<template>
  <v-footer>
    <v-card flat tile width="100%" class="text-center align-content-star" :color="footerBackground">
      <v-card-text>
        <v-btn
          class="hidden-sm-and-down"
          v-for="item in links"
          :key="item.id"
          :color="btnColor"
          text
          @click="go(item.router)"
          small
        >
          {{ item.name }}
        </v-btn>

        <v-btn
          x-small
          class="hidden-md-and-up"
          v-scroll="onScroll"
          v-show="fab"
          fab
          dark
          fixed
          bottom
          right
          color="#303030"
          @click="toTop"
        >
          <v-icon>mdi-arrow-up</v-icon>
        </v-btn>
      </v-card-text>

      <v-divider />

      <v-card-text>
        Copyright &copy; {{ new Date().getFullYear() }} CNT Corp. All rights reserved. |
        <a href="/legal" class="text-decoration-underline">Legal</a> |
        <a href="/sfoa" class="text-decoration-underline">SFOA</a>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {
      links: [
        {
          name: "Home",
          router: "/",
        },
        {
          name: "About Us",
          router: "/about-us",
        },
        {
          name: "Solutions",
          router: "/solutions",
        },
        {
          name: "Partners",
          router: "/partners",
        },
        {
          name: "Careers",
          router: "/careers",
        },
        {
          name: "Contact Us",
          router: "/contact-us",
        },
      ],
      footerBackground: "#f5f5f7",
      btnColor: "#151710",
      fab: false,
    };
  },
  methods: {
    go(ip) {
      this.$router.push({ path: ip });
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
};
</script>
