<template>
  <v-container>
    <v-row class="text-left my-5">
      <v-col cols="12" md="6">
        <v-card>
          <v-img src="/imgs/contact-1.jpg" />
        </v-card>
      </v-col>

      <v-col class="mx-5" cols="12" md="4">
        <v-icon x-large :color="iconColor" class="mt-12">mdi-domain</v-icon>
        <h2 class="mb-5">
          CNT Corp
        </h2>

        <p class="text-justify mb-8">
          10 / 150 Chesterville Rd, <br />
          Moorabbin, VIC 3189 Australia
        </p>
        <div class="text-justify mt-12">
          <div>
            <v-icon :color="iconColor">mdi-phone</v-icon>
            <span>Tel:</span>
            <span><a class="tel ml-3" href="tel:1300 787 178"> 1300 787 178 </a></span>
          </div>
          <div>
            <v-icon :color="iconColor">mdi-fax</v-icon>
            <span>Fax:</span>
            <span><a class="tel ml-2" href="tel:+61 3 955 7338"> +61 3 955 7338</a></span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-divider />

    <v-row justify="center" align="center">
      <div>
        <h1 class="ma-12">Contacting CNT Corp</h1>
      </div>
    </v-row>
    <v-divider />

    <v-row justify="space-around">
      <v-col class="mt-5" md="3" cols="12">
        <div>
          <h2 class="mb-3">
            Support Department:
          </h2>
          <div>
            <v-icon color="primary">mdi-email</v-icon> E-mail:
            <a href="mailto:support@cntcorp.com.au" class="text-decoration-underline">support@cntcorp.com.au </a>
          </div>
        </div>
      </v-col>
      <v-col class="mt-5" md="3" cols="12">
        <div>
          <h2 class="mb-3">
            Billing Department:
          </h2>
          <div>
            <v-icon color="primary">mdi-email</v-icon> E-mail:
            <a href="mailto:billing@cntcorp.com.au" class="text-decoration-underline">billing@cntcorp.com.au </a>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      iconColor: "#65acc1",
    };
  },
};
</script>

<style scoped>
.tel {
  color: inherit;
}
</style>
