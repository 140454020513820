<template>
  <v-container>
    <v-row class="text-left">
      <v-col class="mt-5" cols="12">
        <h2 class="mb-2">
          CNT Corp Standard Form of Agreement
        </h2>

        <iframe id="pdf" src="/pdfs/CNTCorp-SFoA-Core-Terms.pdf"></iframe>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PDFJSViewer",
};
</script>

<style scoped>
#pdf {
  width: 100%;
  height: 79vh;
  min-width: 400px;
}
</style>
