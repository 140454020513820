<template>
  <div class="legal">
    <LegalContent />
  </div>
</template>

<script>
// @ is an alias to /src
import LegalContent from "@/components/LegalContent.vue";

export default {
  name: "Legal",
  components: {
    LegalContent,
  },
};
</script>
