<template>
  <v-app>
    <NavBar />
    <v-main>
      <v-container>
        <router-view />
      </v-container>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "App",

  components: {
    NavBar,
    Footer,
  },

  data: () => ({}),
};
</script>

<style scoped></style>
