<template>
  <div class="contact">
    <ContactContent />
  </div>
</template>

<script>
// @ is an alias to /src
import ContactContent from "@/components/ContactContent.vue";

export default {
  name: "Contact",
  components: {
    ContactContent,
  },
};
</script>
