<template>
  <v-container>
    <v-carousel cycle :show-arrows="false" hide-delimiter-background height="350" class="mt-5">
      <v-carousel-item v-for="(item, i) in items" :key="i" :src="item.src">
        <v-row class="fill-height" align="center" justify="center">
          <div class="display-1">
            {{ item.title }}
            <br />
            {{ item.subtitle }}
          </div>
        </v-row>
      </v-carousel-item>
    </v-carousel>

    <v-row class="text-left">
      <v-col class="mt-5" cols="12">
        <h2 class="font-weight-bold mb-2">WELCOME TO CNT CORP</h2>

        <p class="text-justify">
          More then just a Telecommunications Carrier, our philosophy is based around 'personal service' and we will
          allocate a dedicated account manager to service our wholesale partner.
        </p>
        <p class="text-justify">
          Time is money when it comes to your services, having one point of contact to manage your account will give you
          'peace of mind' so you can concentrate on growing your business without the headaches of dealing with more
          than one supplier.
        </p>
        <p class="text-justify mb-12">
          Harnessing the power of fixed WiMAX, we can offer an alternative last-mile solution to traditional copper
          based services. Where Fibre Optic services may not be the economic method of deployment due to cost, our
          ability to efficiently deploy fixed WiMAX services can offer greater cost benefits whilst maintaining service
          quality to our partners.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          src: "/imgs/img-carousel-1.jpg",
          title: "YOUR",
          subtitle: "BUSINESS",
          solgan: "YOUR BUSINESS",
        },
        {
          src: "/imgs/img-carousel-2.jpg",
          title: "OUR",
          subtitle: "SOLUTIONS",
          solgan: "OUR SOLUTIONS",
        },
      ],
    };
  },
};
</script>
