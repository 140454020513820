<template>
  <div class="career">
    <CareerContent />
  </div>
</template>

<script>
// @ is an alias to /src
import CareerContent from "@/components/CareerContent.vue";

export default {
  name: "Career",
  components: {
    CareerContent,
  },
};
</script>
